import axios from "@/config/http.js";

export const API = {
  // 新品信息查询
  async pageIndex(params) {
    return await axios.get("/manage/new/list", { params });
  },
  // 清空新品
  async deleteNew(id) {
    return await axios.delete(`/manage/new?newGoodsId=${id}`);
  },
  // 新品信息保存
  async addNew(params) {
    return await axios.put(`/manage/new/save`, params);
  },
};
