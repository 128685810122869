<template>
  <div class="new-manage-container">
    <div class="container">
      <a-button class="mb2 mr2" @click="handleAdd">添加</a-button>
      <a-button class="mb2" type="primary" @click="handleSave">保存</a-button>
      <a-table
        :columns="columns"
        :data-source="data"
        :key="data.key"
        :loading="loading"
        :rowClassName="setBackground"
        :scroll="{ y: 700 }"
        :pagination="false"
      >
        <template slot="coverImages" slot-scope="text, record">
          <UploadImage v-model="record.coverImages" :config="uploadConfig" />
        </template>
        <template slot="goodsId" slot-scope="text, record, index">
          <span
            class="hover"
            :style="{ color: `${getColor('主题')}` }"
            :title="record.goodsName"
            @click="openModal(record.goodsId, index)"
            >{{ record.goodsName ? record.goodsName : "跳转地址" }}</span
          >
        </template>
        <template slot="periodValidity" slot-scope="text, record">
          <a-date-picker
            @change="(date) => handleDate(date, record)"
            :allowClear="false"
            format="YYYY-MM-DD"
            v-model="record.periodValidity"
          />
        </template>
        <template slot="validity" slot-scope="text, record">
          <span :style="{ color: `${getColor(record.validity)}` }">{{
            record.validity
          }}</span>
        </template>
        <template slot="operation" slot-scope="text, record, index">
          <span
            class="hover"
            :style="{ color: `${getColor('主题')}` }"
            @click="onDelete(index, record)"
            >删除</span
          >
        </template>
      </a-table>
    </div>

    <!-- 跳转页面 -->
    <JumpAddress
      :visibleModal="visibleModal"
      v-if="visibleModal"
      @goodsData="handleReceiveData"
    />
  </div>
</template>

<script>
const columns = [
  {
    title: "封面",
    ellipsis: true,
    dataIndex: "coverImages",
    scopedSlots: { customRender: "coverImages" },
  },
  {
    title: "跳转地址",
    ellipsis: true,
    dataIndex: "goodsId",
    scopedSlots: { customRender: "goodsId" },
  },
  {
    title: "有效期",
    ellipsis: true,
    dataIndex: "periodValidity",
    scopedSlots: { customRender: "periodValidity" },
  },
  {
    title: "是否过期",
    ellipsis: true,
    dataIndex: "validity",
    scopedSlots: { customRender: "validity" },
  },
  {
    title: "操作",
    ellipsis: true,
    dataIndex: "operation",
    scopedSlots: { customRender: "operation" },
  },
];
import mixins from "@/mixins/mixins.js";
import pagination from "@/mixins/Pagination.js";
import { filterOpt } from "@/utils/utils";
// import upload from "@/mixins/upload.js";
import { getColor } from "../../utils/color";
import moment from "moment";
import JumpAddress from "./jumpAddress.vue";
import UploadImage from "@/components/uploadImage";
import { API } from "@/apix/newServe";
import { tips } from "../../utils/tips";

export default {
  mixins: [pagination, mixins],
  components: {
    JumpAddress,
    UploadImage,
  },
  data() {
    return {
      columns,
      data: [],
      loading: false,
      configFrom: {
        id: null,
      },
      uploadConfig: {
        maxLength: 1,
        maxSize: 10,
      },
      visibleModal: false, // 弹框显示
      currentIndex: null, // 当前跳转点击行的索引

      goodsAddDis: false, // 添加商品时不允许编辑状态和删除商品
    };
  },
  watch: {
    data: {
      handler() {
        if (!this.data[this.data.length - 1].newGoodsId) {
          this.goodsAddDis = true;
        } else {
          this.goodsAddDis = false;
        }
      },
    },
  },
  methods: {
    getColor,
    filterOpt,
    moment,
    // 增加表格项
    handleAdd() {
      this.goodsAddDis = true;
      const newData = {
        coverImages: "",
      };
      this.data = [...this.data, newData];
    },
    // 删除表格项
    onDelete(i, record) {
      if (this.goodsAddDis && record.newGoodsId) {
        this.$message.error("添加商品时不允许编辑商品状态和删除商品");
        return;
      }
      if (!record.newGoodsId) {
        const dataSource = [...this.data];
        this.data = dataSource.filter((item, index) => index !== i);
      } else {
        if (this.data.length < 2) {
          this.pagination.current--;
        }
        let self = this;
        this.$confirm({
          title: "是否确定删除数据?",
          content: "删除后数据无法恢复",
          cancelText: "取消",
          okText: "确定",
          onOk() {
            self.loading = true;
            API.deleteNew(record.newGoodsId).then((res) => {
              tips(res, "删除");
              if (res.success) {
                self.getSourceData();
              }
              self.loading = false;
            });
          },
        });
      }
    },
    openModal(id, index) {
      // 记录当前点击的索引
      this.currentIndex = index;
      this.visibleModal = true;
    },
    closeModal() {
      this.visibleModal = false;
    },
    handleDate(date, record) {
      record.periodValidity = moment(record.periodValidity).format(
        "YYYY-MM-DD"
      );
    },
    // 获取首页所有字典数据
    getSourceData() {
      this.loading = true;
      API.pageIndex().then((res) => {
        this.data = res.data.map((i) => {
          i.key = i.newGoodsId;
          return i;
        });
        this.loading = false;
      });
    },
    // 处理跳转地址选中传回来的数据
    handleReceiveData(data) {
      this.data[this.currentIndex].goodsId = data.goodsId;
      this.data[this.currentIndex].goodsName = data.goodsName;
    },
    // 设置表格行的背景颜色
    setBackground(record) {
      return record.validity === "过期" ? "tableActive" : "";
    },
    // 最后保存
    handleSave() {
      this.data.forEach((item) => {
        if (!item.coverImages) {
          this.$message.error("请上传对应的广告封面");
          throw new Error("请上传对应的广告封面");
        } else if (!item.goodsName || item.goodsName === "跳转地址") {
          this.$message.error("请选择跳转地址");
          throw new Error("请选择跳转地址");
        } else if (!item.periodValidity) {
          this.$message.error("请选择一个有效的时间");
          throw new Error("请选择一个有效的时间");
        }
      });
      let self = this;
      this.$confirm({
        title: "是否确定提交数据?",
        cancelText: "取消",
        okText: "确定",
        onOk() {
          self.loading = true;
          API.addNew(self.data).then((res) => {
            tips(res, "保存");
            if (res.success) {
              self.getSourceData();
            }
            self.loading = false;
          });
        },
      });
    },
  },
  created() {
    this.getSourceData();
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding: 2rem;
  min-height: 100%;
  background: #fff;
}
</style>
